import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import LoadingComponent from './LoadingComponent';
import { UserIsAuthenticated } from '../../features/auth/authWrapper';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';
// import ReactGA from 'react-ga';

const AsyncHomePage = Loadable({
  loader: () => import('../../features/home/HomePage'),
  loading: LoadingComponent
});
const AsyncUserAgreement = Loadable({
  loader: () => import('../../features/home/HomePage'),
  loading: LoadingComponent
});
const AsyncPostDashboard = Loadable({
  loader: () => import('../../features/post/PostDashboard/PostDashboard'),
  loading: LoadingComponent
});
const AsyncNavBar = Loadable({
  loader: () => import('../../features/nav/NavBar/NavBar'),
  loading: LoadingComponent
});
const AsyncPostForm = Loadable({
  loader: () => import('../../features/post/PostForm/PostForm'),
  loading: LoadingComponent
});
const AsyncFollowForm = Loadable({
  loader: () => import('../../features/user/FollowForm/FollowForm'),
  loading: LoadingComponent
});
const AsyncSettingsDashboard = Loadable({
  loader: () => import('../../features/user/Settings/SettingsDashboard'),
  loading: LoadingComponent
});
const AsyncRequestForm = Loadable({
  loader: () => import('../../features/request/RequestForm/RequestForm'),
  loading: LoadingComponent
});
const AsyncAdminDashboard = Loadable({
  loader: () => import('../../features/admin/AdminDashboard'),
  loading: LoadingComponent
});
const AsyncUserDetailedPage = Loadable({
  loader: () => import('../../features/user/UserDetailed/UserDetailedPage'),
  loading: LoadingComponent
});
const AsyncPeopleDashboard = Loadable({
  loader: () => import('../../features/user/PeopleDashboard/PeopleDashboard'),
  loading: LoadingComponent
});
const AsyncModalManager = Loadable({
  loader: () => import('../../features/modals/ModalManager'),
  loading: LoadingComponent
});
const AsyncErrorPermission = Loadable({
  loader: () => import('../../app/layout/ErrorPermission'),
  loading: LoadingComponent
});
const AsyncConfirmEmail = Loadable({
  loader: () => import('../../app/layout/ConfirmEmail'),
  loading: LoadingComponent
});
const AsyncNotFound = Loadable({
  loader: () => import('../../app/layout/NotFound'),
  loading: LoadingComponent
});

class App extends Component {
  render() {
    return (
      <div>
        <AsyncModalManager />
        <Switch>
          <Route exact path="/" component={AsyncHomePage} />
        </Switch>

        <Route
          path="/(.+)"
          render={() => (
            <div>
              <AsyncNavBar />
              <Container className="main">
                <Switch>
                  <Route path="/posts/public" component={AsyncPostDashboard} />
                  <Route path="/error" component={AsyncNotFound} />
                  <Route component={AsyncNotFound} />
                </Switch>
              </Container>
            </div>
          )}
        />
      </div>
    );
  }
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
// import 'semantic-ui-css/semantic.min.css';
import './semantic/dist/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './index.css';
import App from './app/layout/App';
import registerServiceWorker from './registerServiceWorker';
import { configureStore } from './app/store/configureStore';
import ScrollToTop from './app/common/util/ScrollToTop';
import ReactGA from 'react-ga';
import { PropTypes } from 'prop-types';

const store = configureStore();

const rootEl = document.getElementById('root');

//Hide all console logs in production
function noop() {}
if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactGA.initialize('UA-141182800-1', {
  titleCase: false
  // debug: true
});

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

let render = () => {
  const confirmOptions = {
    okText: 'OK',
    cancelText: 'Cancel'
  };
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <GAListener>
          <ScrollToTop>
            <ReduxToastr
              timeOut={4000}
              className="aw-toastr"
              position="bottom-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              confirmOptions={confirmOptions}
            />
            <App />
          </ScrollToTop>
        </GAListener>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./app/layout/App', () => {
    setTimeout(render);
  });
}

store.firebaseAuthIsReady.then(() => {
  render();
});

registerServiceWorker();

import { createReducer } from '../../app/common/util/reducerUtil';
import { CREATE_POST, DELETE_POST, FETCH_POSTS } from './postConstants';

const initialState = [];

export const createPost = (state, payload) => {
  return [...state, Object.assign({}, payload.post)];
};

export const updatePost = (state, payload) => {
  return [
    ...state.filter(post => post.id !== payload.post.id),
    Object.assign({}, payload.post)
  ];
};

export const deletePost = (state, payload) => {
  return [...state.filter(post => post.id !== payload.postId)];
};

export const fetchPosts = (state, payload) => {
  console.log('Fetching posts in postReducer:');
  console.log(payload.posts);
  return payload.posts;
};

export default createReducer(initialState, {
  [CREATE_POST]: createPost,
  [DELETE_POST]: deletePost,
  [FETCH_POSTS]: fetchPosts
});

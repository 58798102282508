import { createReducer } from '../../app/common/util/reducerUtil';
import { DELETE_USER, FETCH_USERS } from './usersConstants';

const initialState = [];

// export const updatePost = (state, payload) => {
//   return [
//     ...state.filter(post => post.id !== payload.post.id),
//     Object.assign({}, payload.post)
//   ];
// };

export const deleteUser = (state, payload) => {
  return [...state.filter(user => user.id !== payload.userId)];
};

export const fetchUsers = (state, payload) => {
  console.log('Fetching users in userReducer:');
  console.log(payload.users);
  return payload.users;
};

export default createReducer(initialState, {
  [DELETE_USER]: deleteUser,
  [FETCH_USERS]: fetchUsers
});

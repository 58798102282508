import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBYuKldERFAIAa4DKEInte0FD8t6my4Uww',
  authDomain: 'api-14p9.firebaseapp.com',
  databaseURL: 'https://api-14p9.firebaseio.com',
  projectId: 'api-14p9',
  storageBucket: 'api-14p9.appspot.com',
  messagingSenderId: '647986159112'
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const settings = {
  timestampsInSnapshots: true
};
firestore.settings(settings);
export default firebase;

import { createReducer } from '../../app/common/util/reducerUtil';
import {
  CREATE_REQUEST,
  DELETE_REQUEST,
  FETCH_REQUESTS
} from './requestConstants';

const initialState = [];

export const createRequest = (state, payload) => {
  return [...state, Object.assign({}, payload.request)];
};

export const updateRequest = (state, payload) => {
  return [
    ...state.filter(request => request.id !== payload.request.id),
    Object.assign({}, payload.request)
  ];
};

export const deleteRequest = (state, payload) => {
  return [...state.filter(request => request.id !== payload.requestId)];
};

export const fetchRequests = (state, payload) => {
  console.log('Fetching requests in requestReducer:');
  console.log(payload.requests);
  return payload.requests;
};

export default createReducer(initialState, {
  [CREATE_REQUEST]: createRequest,
  [DELETE_REQUEST]: deleteRequest,
  [FETCH_REQUESTS]: fetchRequests
});

import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import postReducer from '../../features/post/postReducer';
import modalsReducer from '../../features/modals/modalReducer';
import authReducer from '../../features/auth/authReducer';
import requestReducer from '../../features/request/requestReducer';
import usersReducer from '../../features/users/usersReducer';
import asyncReducer from '../../features/async/asyncReducer';

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  posts: postReducer,
  form: FormReducer,
  modals: modalsReducer,
  auth: authReducer,
  users: usersReducer,
  requests: requestReducer,
  async: asyncReducer,
  toastr: toastrReducer
});

export default rootReducer;
